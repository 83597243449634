






















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Stack, StackItem } from "vue-stack-grid";

@Component({
  components: {
    Stack,
    StackItem
  }
})
export default class Galeria extends Vue {
  private galeria: Array<any> = [];
  private currentPage = 1;
  private total = 0;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 9)) {
      this.getGaleria();
    }
  }

  private mounted() {
    this.getGaleria();
  }

  private getGaleria() {
    this.$axios
      .get("galeria/general?pagina=" + this.currentPage)
      .then(res => {
        let base = process.env.VUE_APP_BASE_URL;
        this.galeria = res.data.galerias;

        for (let i = 0; i < this.galeria.length; i++) {
          if (
            this.galeria[i].imagen_previa &&
            this.galeria[i].imagen_previa != ""
          ) {
            this.galeria[i].imagen_previa =
              base + this.galeria[i].imagen_previa;
            this.galeria[i].imagen_previa;
          } else {
            this.galeria[
              i
            ].imagen_previa = require("@/assets/logos/logo300.webp");
          }
        }
        this.total = res.data.total;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private irGaleria(nombre_web: any) {
    if (
      this.$router.currentRoute.name == "DetalleGaleria" &&
      this.$router.currentRoute.params.nombre == nombre_web
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "DetalleGaleria",
          params: { nombre: nombre_web }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "Enero";
    } else if (mesInicio == 2) {
      mesInicio = "Febrero";
    } else if (mesInicio == 3) {
      mesInicio = "Marzo";
    } else if (mesInicio == 4) {
      mesInicio = "Abril";
    } else if (mesInicio == 5) {
      mesInicio = "Mayo";
    } else if (mesInicio == 6) {
      mesInicio = "Junio";
    } else if (mesInicio == 7) {
      mesInicio = "Julio";
    } else if (mesInicio == 8) {
      mesInicio = "Agosto";
    } else if (mesInicio == 9) {
      mesInicio = "Septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "Octubre";
    } else if (mesInicio == 11) {
      mesInicio = "Noviembre";
    } else {
      mesInicio = "Diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }
}
